import React from "react"
import { cn } from "@daybridge/cn"
import { Label, LabelProps } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { IconId } from "../../../../../../data/_gen/types"
import { Area } from "../../../../../../data/areas/Area"
import { styleForHue } from "../../../../../../lib/styleForHue"

type AreaLabelProps = Omit<LabelProps, "children"> & {
  area?: Area | null
  inherited?: boolean
}

const AreaLabelFn = React.forwardRef(
  (props: AreaLabelProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, inherited, area, ...rest } = props
    const t = useTranslations("areas")

    return (
      <Label
        ref={ref}
        className={cn(
          "relative",
          "text-sm",
          "rounded-full",
          "px-[0.75em]",
          !area || inherited
            ? [
                "border border-dashed",
                "bg-none ring-0 shadow-none",
                "dark:text-adaptive-medium-contrast",
                inherited ? "border-adaptive-tint-heavy" : "border-tint-heavy",
              ]
            : ["my-px"],
          className,
        )}
        icon={area?.icon || IconId.Area}
        theme={area ? "adaptive" : "neutral"}
        style={{
          ...styleForHue(area?.hue || 0),
        }}
        {...rest}
      >
        {area?.name || t("no_area")}
      </Label>
    )
  },
)
AreaLabelFn.displayName = "AreaLabel"

export const AreaLabel = React.memo(AreaLabelFn) as typeof AreaLabelFn
