import { MenuItem, MenuItemGroup, MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useMemo } from "react"
import { useGeoData } from "../../data/geodata/useGeoData"
import { Country } from "../../data/_gen/types"

export type CountriesMenuInstanceOptions = {
  selectedItems?: Country[]
  onItemSelect?: (countryCode: Country) => void
}

export type CountriesMenuOptions = {
  selectionMode?: MenuItemGroup["selectionMode"]
}

type CountriesMenuInstanceGenerator = (
  opts?: CountriesMenuInstanceOptions,
) => MenuPage

export const useCountriesMenu = (
  options?: CountriesMenuOptions,
): CountriesMenuInstanceGenerator => {
  const t = useTranslations("countries")
  const tNoResults = useTranslations("default_no_results_state")
  const { data: geodata } = useGeoData()
  const sortedCountries = useMemo(() => {
    if (geodata?.countries) {
      return Object.values(geodata?.countries).sort((c1, c2) => {
        return c1.name.localeCompare(c2.name)
      })
    }
    return []
  }, [geodata?.countries])

  const items = useCallback(
    (opts?: CountriesMenuInstanceOptions): MenuItemGroup => ({
      groupId: "countries",
      selectionMode: options?.selectionMode,
      items: sortedCountries.map(
        (country): MenuItem => ({
          id: country.code,
          title: country.name,
          icon: <>{country.flagEmoji}</>,
          maxSearchDepth: 0,
          selected: !!opts?.selectedItems?.find(
            (selectedCountry) => selectedCountry.code === country.code,
          ),
          onSelect: () => opts?.onItemSelect?.(country),
        }),
      ),
    }),
    [sortedCountries, options?.selectionMode],
  )

  return useCallback(
    (opts?: CountriesMenuInstanceOptions): MenuPage => ({
      prompt: t("search_countries_and_regions") + "...",
      promptIcon: "World",
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      defaultHighlightedValue: opts?.selectedItems?.[0]?.code,
      items: [items(opts)],
    }),
    [items, tNoResults, t],
  )
}
