import { useIconsQuery } from "../_gen/hooks"
import { IconsQuery } from "../_gen/operations"
import { Icon, IconSet } from "./IconSet"

const select = (data: IconsQuery): IconSet[] => {
  const iconGroups = data.iconGroups?.edges || []

  return iconGroups.map((iconGroup) => {
    const icons = iconGroup.node.icons.edges.map((icon) => {
      const i: Icon = {
        id: icon.node.id,
        name: icon.node.name,
        queryStrings: icon.node.queryStrings,
      }
      return i
    })

    const s: IconSet = {
      id: iconGroup.node.id,
      name: iconGroup.node.name,
      icon: iconGroup.node.icon,
      icons,
    }

    return s
  })
}

export const useIcons = () =>
  useIconsQuery(undefined, {
    select,
    staleTime: 60 * 60 * 1000,
  })
