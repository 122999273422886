import React, { ComponentProps } from "react"
import { Link as NextIntlLink } from "../../lib/navigation"

const LinkFn = React.forwardRef(
  (
    props: ComponentProps<typeof NextIntlLink>,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const { children, className, ...rest } = props

    return (
      <NextIntlLink
        className={`
          underline
          text-info-body 
          ${className || ""}
        `}
        ref={ref}
        {...rest}
      >
        {children}
      </NextIntlLink>
    )
  },
)
LinkFn.displayName = "Link"

export const Link = React.memo(LinkFn) as typeof LinkFn
