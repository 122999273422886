import { Label, MenuPage } from "@daybridge/components"
import { useLocale, useTranslations } from "next-intl"
import { useMemo } from "react"
import {
  betaLocales,
  localeToEmojiFlag,
  localeToLanguageName,
  locales,
} from "../../config/locales"
import { usePathname, useRouter } from "../../lib/navigation"

export const useLanguageMenu = (): MenuPage => {
  const t = useTranslations("language")
  const pathname = usePathname()
  const router = useRouter()
  const locale = useLocale()

  return useMemo(
    (): MenuPage => ({
      defaultHighlightedValue: `language-${locale}`,
      prompt: t("choose_a_language") + "...",
      items: [
        {
          groupId: "langauge",
          selectionMode: "radio",
          items: [
            ...locales.concat(betaLocales).map((l) => ({
              id: `language-${l}`,
              title: localeToLanguageName(l),
              rightContent: betaLocales.includes(l) ? (
                <Label icon="Atom" className="text-sm" theme="warning">
                  {t("beta")}
                </Label>
              ) : undefined,
              icon: <>{localeToEmojiFlag(l)}</>,
              selected: locale === l,
              onSelect: () => {
                router.push(pathname, { locale: l })
              },
            })),
          ],
        },
      ],
    }),
    [locale, router, t, pathname],
  )
}
