import { MenuPage, useToast } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback } from "react"
import { v4 } from "uuid"
import { useConnectOAuthAccount } from "../../data/oauth/useConnectOAuthAccount"
import { FeedbackType, IconId, OAuthProviderId } from "../../data/_gen/types"
import { useSendFeedbackMutation } from "../../data/_gen/hooks"

export const useConnectAccountMenu = () => {
  const t = useTranslations("connected_accounts")
  const toast = useToast()
  const connect = useConnectOAuthAccount()

  const { mutateAsync: sendFeedback } = useSendFeedbackMutation()
  const onSendIcloudFeedback = useCallback(async () => {
    await sendFeedback(
      {
        input: {
          feedbackType: FeedbackType.IdeaSuggestion,
          idempotencyKey: v4(),
          metadata: {
            provider: "icloud",
          },
          message: "Provider request: iCloud",
        },
      },
      {
        onSuccess: () => {
          toast.success({
            title: t("registered_interest"),
            icon: IconId.Tick,
          })
        },
      },
    )
  }, [sendFeedback, t, toast])

  const onSendOutlookFeedback = useCallback(async () => {
    await sendFeedback(
      {
        input: {
          feedbackType: FeedbackType.IdeaSuggestion,
          idempotencyKey: v4(),
          metadata: {
            provider: "outlook",
          },
          message: "Provider request: Outlook",
        },
      },
      {
        onSuccess: () => {
          toast.success({
            title: t("registered_interest"),
            icon: IconId.Tick,
          })
        },
      },
    )
  }, [sendFeedback, t, toast])

  const items = useCallback(
    (areaId?: string): MenuPage["items"] => [
      {
        groupId: "connect",
        title: t("connect_an_account"),
        items: [
          {
            id: `connect-google`,
            title: "Google",
            icon: IconId.Google,
            onSelect: async () => {
              await connect(OAuthProviderId.Google, areaId)
            },
          },
        ],
      },
      {
        groupId: "register-interest",
        title: t("register_interest"),
        items: [
          {
            id: "Apple",
            title: "iCloud",
            icon: IconId.Apple,
            maxSearchDepth: 0,
            onSelect: async () => {
              await onSendIcloudFeedback()
            },
          },
          {
            id: "Outlook",
            title: "Outlook",
            icon: IconId.Envelope,
            maxSearchDepth: 0,
            onSelect: async () => {
              await onSendOutlookFeedback()
            },
          },
        ],
      },
    ],
    [connect, t, onSendIcloudFeedback, onSendOutlookFeedback],
  )

  return useCallback(
    (opts?: { areaId?: string }): MenuPage => ({
      items: items(opts?.areaId),
      prompt: t("connect_an_account") + "...",
    }),
    [items, t],
  )
}
