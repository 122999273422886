import { useQueryClient } from "@tanstack/react-query"
import { GraphQLError } from "graphql-request/dist/types"
import { useCallback } from "react"
import { UpdateOperation, useOptimisticBuffer } from "@daybridge/optimism"
import { useAccountQuery, useUpdateAccountMutation } from "../_gen/hooks"
import {
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "../_gen/operations"

export const useUpdateAccount = () => {
  const queryClient = useQueryClient()
  const { update, complete } = useOptimisticBuffer<
    unknown,
    UpdateAccountMutationVariables
  >("Account")
  return useUpdateAccountMutation({
    onMutate: useCallback(
      (variables: UpdateAccountMutationVariables) => {
        return update("account", variables)
      },
      [update],
    ),
    onSuccess: useCallback(
      async (
        _response: UpdateAccountMutation,
        variables: UpdateAccountMutationVariables,
        operation: UpdateOperation<UpdateAccountMutationVariables> | undefined,
      ) => {
        await queryClient.invalidateQueries(useAccountQuery.getKey())
        if (operation) {
          complete(operation.operationId)
        }
      },
      [queryClient, complete],
    ),
    onError: useCallback(
      (
        error: GraphQLError,
        variables: UpdateAccountMutationVariables,
        operation: UpdateOperation<UpdateAccountMutationVariables> | undefined,
      ) => {
        if (operation) {
          complete(operation.operationId)
        }
      },
      [complete],
    ),
  })
}
