import { useCallback } from "react"
import { useGenerateOAuthConnectUrlMutation } from "../_gen/hooks"
import { OAuthProviderId } from "../_gen/types"

// A `StartConnectionFunction` takes a Provider ID and returns void.
export type StartConnectionFunction = (
  providerId: OAuthProviderId,
  areaId?: string,
) => Promise<void>

/**
 * `useConnectOAuthAccount` kicks off the OAuth integration flow
 * for the given provider. It opens up a new window, and then closes
 * that window once the flow is complete.
 */
export const useConnectOAuthAccount = (): StartConnectionFunction => {
  // Set up mutation for contacting the server to generate an auth URL
  const { mutateAsync: generateAuthUrl } = useGenerateOAuthConnectUrlMutation()

  return useCallback(
    async (providerId: OAuthProviderId, areaId?: string) => {
      // Generate the auth URL
      await generateAuthUrl(
        {
          input: {
            providerId,
            redirectUri:
              process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
                ? undefined // We don't submit this in production - the server decides
                : (window?.location.origin || "") + "/connect",
            areaId,
          },
        },
        {
          onSuccess: (data) => {
            window.open(data.generateOAuthConnectUrl?.url, "_blank")
          },
        },
      )
    },
    [generateAuthUrl],
  )
}
