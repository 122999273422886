import {
  ColorPicker,
  Combobox,
  Dropdown,
  Icon,
  MenuItem,
  MenuItemGroup,
  MenuPage,
} from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useMemo, useState } from "react"
import { v4 } from "uuid"
import { cn } from "@daybridge/cn"
import { Area } from "../../data/areas/Area"
import { useIconsMenu } from "../icons/icons-menu"
import { useAreaOperations, useAreas } from "../../data/areas/useAreas"
import {
  useCalendarOperations,
  useCalendars,
} from "../../data/calendars/useCalendars"
import { IconId, OAuthProviderId } from "../../data/_gen/types"
import { GoogleCalendarProvider } from "../../data/calendars/Calendar"
import { AreaLabel } from "../../app/[locale]/(boundary)/(calendar)/_components/areas/AreaLabel"
import {
  useOAuthConnectionOperations,
  useOAuthConnections,
} from "../../data/oauth/useOAuthConnections"

export interface AreasMenuGeneratorOptions {
  selectedAreas?: string[]
  noAreaOptionSelected?: boolean
  onNoAreaChange?: (selected: boolean) => void
  onAreaSelect?: (area: Area, selected: boolean) => void
  allowReorder?: boolean
  noAreaText?: string
  noAreaIcon?: string
}

type AreaMenuGenerator = (opts: AreasMenuGeneratorOptions) => MenuPage

export interface AreasMenuOptions {
  selectionMode?: MenuItemGroup["selectionMode"]
  showAreaEditMenu?: boolean
  showNoAreaOption?: boolean
  showCalendarCount?: boolean

  // Override interal renaming
  creating?: boolean
  onCreateComplete?: () => void
}

export const useAreasMenu = (options?: AreasMenuOptions): AreaMenuGenerator => {
  // Translations
  const t = useTranslations("areas")
  const tNoResults = useTranslations("default_no_results_state")

  const { data: areas = [] } = useAreas()

  const {
    create: { mutate: createArea },
    delete: { mutate: deleteArea },
    update: { mutate: updateArea },
    reorder: {
      mutate: reorderAreas,
      moveItemUp: moveAreaUp,
      moveItemDown: moveAreaDown,
    },
  } = useAreaOperations()

  // Renaming
  const [renamingAreaId, setRenamingAreaId] = useState<string | undefined>()

  // Icons
  const icons = useIconsMenu()

  // Calendars
  const { data: oauthConnections } = useOAuthConnections()
  const {
    update: { mutate: updateAccount },
  } = useOAuthConnectionOperations()

  const { data: calendarGroups } = useCalendars()
  const {
    update: { mutate: updateCalendar },
  } = useCalendarOperations()
  const filteredCalendarGroups = calendarGroups?.filter(
    (g) => g.calendars.filter((c) => c.enabled).length > 0,
  )
  const tCalendars = useTranslations("calendars")
  const tAccounts = useTranslations("connected_accounts")
  const enabledCalendarsWithAreas = useMemo(
    () =>
      calendarGroups
        ?.flatMap((group) => group.calendars)
        .filter(
          (calendar) =>
            calendar.enabled &&
            !!(calendar.defaultArea || calendar.account?.defaultArea),
        ),
    [calendarGroups],
  )

  // Create a map of Area ID -> List of Calendar IDs
  const areaCalendarMap = useMemo(() => {
    const map = new Map<string, string[]>()
    enabledCalendarsWithAreas?.forEach((calendar) => {
      const area = calendar.defaultArea || calendar.account?.defaultArea
      if (area) {
        const list = map.get(area.id) || []
        list.push(calendar.id)
        map.set(area.id, list)
      }
    })
    return map
  }, [enabledCalendarsWithAreas])

  // Area customization
  const areaCustomizationMenu = useCallback(
    (area: Area, opts: AreasMenuGeneratorOptions): MenuPage => {
      const order = areas.map((area) => area.id)
      return {
        breadcrumbHue: area.hue,
        items: [
          ...(oauthConnections.length > 0 ||
          (filteredCalendarGroups || []).length > 0
            ? [
                {
                  groupId: "calendars-and-accounts",
                  items: [
                    ...(oauthConnections.length > 0
                      ? [
                          {
                            id: "assign-accounts",
                            title: t("assign_accounts"),
                            icon: "connection" as IconId, // TODO ICON
                            expandChildrenAs: {
                              component: Combobox,
                              mode: "combobox" as const,
                            },
                            children: {
                              prompt: tAccounts("search_accounts") + "...",
                              items: [
                                {
                                  groupId: "accounts",
                                  selectionMode: "checkbox" as const,
                                  items: oauthConnections.map((connection) => ({
                                    id: connection.id,
                                    hue: connection.defaultArea?.hue,
                                    title:
                                      connection.customName ||
                                      connection.email ||
                                      connection.providerName,
                                    description: connection.customName
                                      ? connection.email
                                      : undefined,
                                    rightContent: (
                                      <AreaLabel
                                        area={connection.defaultArea}
                                        className="ml-4"
                                      />
                                    ),
                                    selected:
                                      connection.defaultArea?.id === area.id,
                                    onSelect: () => {
                                      updateAccount({
                                        input: {
                                          id: connection.id,
                                          patch: {
                                            defaultArea:
                                              connection.defaultArea?.id ===
                                              area.id
                                                ? null
                                                : area.id,
                                          },
                                        },
                                      })
                                    },
                                  })),
                                },
                              ],
                            },
                          },
                        ]
                      : []),
                    ...((filteredCalendarGroups || []).length > 0
                      ? [
                          {
                            id: "assign_calendars",
                            title: t("assign_calendars"),
                            icon: IconId.Calendar,
                            children: {
                              prompt: tCalendars("search_calendars") + "...",
                              items:
                                filteredCalendarGroups?.map(
                                  (group): MenuItemGroup => {
                                    const providerTitle =
                                      group.providerId ===
                                      OAuthProviderId.Daybridge
                                        ? "Daybridge"
                                        : (group as GoogleCalendarProvider)
                                            .customName ?? group.username
                                    return {
                                      groupId: group.id,
                                      title: providerTitle,
                                      icon: group.icon,
                                      selectionMode: "checkbox" as const,
                                      items: group.calendars
                                        .filter((calendar) => calendar.enabled)
                                        .map((calendar) => {
                                          const selected =
                                            (
                                              calendar.defaultArea ||
                                              calendar.account?.defaultArea
                                            )?.id === area.id
                                          return {
                                            id: calendar.id,
                                            title: calendar.name,
                                            maxSearchDepth: 0,
                                            rightContent: (
                                              <AreaLabel
                                                area={
                                                  calendar.defaultArea ??
                                                  calendar.account?.defaultArea
                                                }
                                                inherited={
                                                  !!calendar.account
                                                    ?.defaultArea &&
                                                  !calendar.defaultArea
                                                }
                                                className="ml-4"
                                              />
                                            ),
                                            hue: calendar.defaultArea
                                              ? calendar.defaultArea.hue
                                              : calendar.account?.defaultArea
                                              ? calendar.account.defaultArea.hue
                                              : undefined,
                                            selected,
                                            disabled:
                                              !!calendar.account?.defaultArea &&
                                              !calendar.defaultArea &&
                                              selected,
                                            onSelect: () => {
                                              updateCalendar({
                                                input: {
                                                  id: calendar.id,
                                                  patch: {
                                                    defaultArea: selected
                                                      ? null
                                                      : area.id,
                                                  },
                                                },
                                              })
                                            },
                                          }
                                        }),
                                    }
                                  },
                                ) || [],
                            },
                            expandChildrenAs: {
                              component: Combobox,
                              mode: "combobox" as const,
                            },
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          {
            groupId: "rename-colour-and-icon-pickers",
            items: [
              {
                id: "rename",
                title: t("rename"),
                icon: "Pencil",
                onSelect: () => {
                  setRenamingAreaId(area.id)
                  return false
                },
              },
              {
                id: "icon_picker",
                icon: area.icon || "Area",
                title: t("choose_icon"),
                expandChildrenAs: {
                  mode: "combobox" as const,
                  component: Combobox,
                },
                children: icons(
                  area.icon ? [area.icon] : [],
                  (icon) => {
                    updateArea({
                      input: {
                        id: area.id,
                        patch: { icon: icon ? { id: icon } : null },
                      },
                    })
                  },
                  true,
                ),
              },
              <ColorPicker
                className="p-2"
                key="color_picker"
                selectedHue={area.hue}
                onHueSelect={(hue) => {
                  if (hue !== area.hue) {
                    updateArea({
                      input: {
                        id: area.id,
                        patch: { color: { hue } },
                      },
                    })
                  }
                }}
              />,
            ],
          },
          ...(opts?.allowReorder
            ? [
                {
                  groupId: "move-up-down",
                  items: [
                    {
                      id: "move_up",
                      icon: "ArrowUp",
                      title: t("move_up"),
                      onSelect: () => {
                        moveAreaUp(order, area.id)
                      },
                      maxSearchDepth: 0, // Doesn't make sense to search for this
                    },
                    {
                      id: "move_down",
                      icon: "ArrowDown",
                      title: t("move_down"),
                      onSelect: () => {
                        moveAreaDown(order, area.id)
                      },
                      maxSearchDepth: 0, // Doesn't make sense to search for this
                    },
                  ],
                },
              ]
            : []),
          {
            groupId: "delete",
            items: [
              {
                id: "delete",
                icon: "TrashEmpty",
                title: t("delete"),
                hue: 0,
                onSelect: () => {
                  void deleteArea({ input: { id: area.id } })
                },
              },
            ],
          },
        ],
      }
    },
    [
      t,
      updateArea,
      deleteArea,
      icons,
      setRenamingAreaId,
      areas,
      moveAreaUp,
      moveAreaDown,
      filteredCalendarGroups,
      updateCalendar,
      tCalendars,
      tAccounts,
      oauthConnections,
      updateAccount,
    ],
  )

  // Individual areas
  const areaItem = useCallback(
    (area: Area, opts: AreasMenuGeneratorOptions): MenuItem => {
      const selected = opts?.selectedAreas?.includes(area.id) ?? false

      const calendars = areaCalendarMap.get(area.id) || []
      const description =
        calendars.length > 1
          ? t("n_calendars", { n: calendars.length })
          : calendars.length === 1
          ? t("1_calendar")
          : t.rich("no_calendars", {
              Ellipsis: () => (
                <Icon
                  name={IconId.EllipsisHorizontal}
                  className={cn("inline", "w-2")}
                />
              ),
            })

      return {
        id: area.id,
        title: area.name,
        description: options?.showCalendarCount ? description : undefined,
        hue: area.hue,
        icon: area.icon,
        selected,
        renaming: renamingAreaId === area.id,
        maxNameLength: 32,
        onRename: (name: string) => {
          if (name === area.name) return
          void updateArea({
            input: { id: area.id, patch: { name } },
          })
        },
        onRenameCancel: () => {
          setRenamingAreaId(undefined)
        },
        onSelect: () => opts?.onAreaSelect?.(area, !selected),
        expandChildrenAs: { mode: "menu", component: Dropdown },
        maxSearchDepth: 1,
        children: options?.showAreaEditMenu
          ? areaCustomizationMenu(area, opts)
          : undefined,
      }
    },
    [
      renamingAreaId,
      updateArea,
      areaCustomizationMenu,
      options,
      setRenamingAreaId,
      areaCalendarMap,
      t,
    ],
  )

  // Menu items
  const items = useCallback(
    (opts: AreasMenuGeneratorOptions): MenuItemGroup[] => {
      return [
        ...(options?.showNoAreaOption
          ? [
              {
                groupId: "no-area",
                selectionMode: options.selectionMode,
                items: [
                  {
                    id: "no-area",
                    title: opts.noAreaText
                      ? opts.noAreaText
                      : "(" + t("no_area") + ")",
                    selected: opts.noAreaOptionSelected,
                    onSelect: () =>
                      opts.onNoAreaChange?.(!opts.noAreaOptionSelected),
                    icon: opts.noAreaIcon || "Minus",
                  },
                ],
              },
            ]
          : []),
        {
          groupId: "areas",
          selectionMode: options?.selectionMode,
          onReorder: opts?.allowReorder
            ? (_, order) => reorderAreas(order)
            : undefined,
          items: [
            ...(areas?.map((area) => areaItem(area, opts)) || []),
            ...(options?.creating
              ? [
                  {
                    id: "create-area",
                    title: t("new_area"),
                    renaming: true,
                    onRename: (name: string) => {
                      options?.onCreateComplete?.()
                      createArea({
                        input: {
                          idempotencyKey: v4(),
                          create: {
                            name,
                            color: {
                              hue: Math.floor(Math.random() * 12) * 30,
                            },
                          },
                        },
                      })
                    },
                    onRenameCancel: () => {
                      options?.onCreateComplete?.()
                    },
                    icon: "Area",
                  },
                ]
              : []),
          ],
        },
      ]
    },
    [options, t, areaItem, createArea, areas, reorderAreas],
  )

  // Menu page
  return useCallback(
    (opts: AreasMenuGeneratorOptions) => ({
      prompt: t("search_areas") + "...",
      noResultsIcon: "Area",
      emptyStateIcon: "Area",
      emptyStateTitle: t("create_your_first_area"),
      emptyStateDescription: t("create_your_first_area_description"),
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      items: items(opts),
    }),
    [t, tNoResults, items],
  )
}
