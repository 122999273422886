import {
  Combobox,
  Dropdown,
  Label,
  MenuItem,
  MenuItemGroup,
  MenuPage,
} from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useState } from "react"
import {
  Calendar,
  CalendarAccount,
  GoogleCalendarProvider,
} from "../../data/calendars/Calendar"
import {
  useCalendarOperations,
  useCalendars,
} from "../../data/calendars/useCalendars"
import { useBeginFlow } from "../../app/[locale]/(boundary)/_providers/FlowProvider"
import { useTagsMenu } from "../tags/tags-menu"
import { useAreasMenu } from "../areas/areas-menu"
import { useAccount } from "../../data/account/useAccount"
import {
  CalendarParticipantStatus,
  IconId,
  OAuthConnectionStatus,
  OAuthProviderId,
} from "../../data/_gen/types"
import { AreaLabel } from "../../app/[locale]/(boundary)/(calendar)/_components/areas/AreaLabel"
import { Tag } from "../../data/tags/Tag"
import { useCalendarSharingDescription } from "../../data/calendars/useCalendarSharingDescription"
import { useCalendarPermissions } from "../../data/calendars/useCalendarPermissions"

export type CalendarMenuInstanceOptions = {
  selectedCalendarIds?: string[]
  onCalendarSelect?: (calendar: Calendar) => void
  isSelectable?: (calendar: Calendar) => boolean
}

export type CalendarMenuOptions = {
  noCalendarOptionSelected?: boolean
  onNoCalendarChange?: (selected: boolean) => void
  selectionMode?: MenuItemGroup["selectionMode"]
  account?: CalendarAccount
  includeDisabled?: boolean
}

type CalendarMenuInstanceGenerator = (
  opts?: CalendarMenuInstanceOptions,
) => MenuPage

export const useCalendarMenu = (
  options?: CalendarMenuOptions,
): CalendarMenuInstanceGenerator => {
  const t = useTranslations("calendars")
  const tNoResults = useTranslations("default_no_results_state")

  // Calendars
  const { data: groupedCalendarsBeforeFilter } = useCalendars(
    options?.includeDisabled,
  )
  const {
    update: { mutate: updateCalendar },
    reorder: { mutate: reorderCalendars, moveItemUp, moveItemDown },
  } = useCalendarOperations()

  let groupedCalendars: CalendarAccount[] | undefined
  if (options?.account) {
    groupedCalendars = groupedCalendarsBeforeFilter?.filter(
      (group) => group.id === options.account?.id,
    )
  } else {
    groupedCalendars = groupedCalendarsBeforeFilter?.filter(
      (group) => group.calendars.length > 0,
    )
  }

  // Permissions
  const { data: user } = useAccount()
  const permissionsFor = useCalendarPermissions()

  // Editing
  const beginFlow = useBeginFlow()
  const [renaming, setRenaming] = useState<string | undefined>(undefined)
  const onRename = useCallback(
    (calendar: Calendar, newName: string) => {
      setRenaming(undefined)
      const renameForAll = permissionsFor(calendar).canRenameForEveryone
      updateCalendar({
        input: {
          id: calendar.id,
          patch: renameForAll
            ? {
                name: newName,
                customName: null,
              }
            : {
                customName: newName || null,
              },
        },
      })
    },
    [updateCalendar, permissionsFor],
  )

  const onTagSelect = useCallback(
    (calendar: Calendar, tag: Tag, selected: boolean) => {
      const existingTags = calendar.defaultTags
      const newTags = selected
        ? existingTags.find((existingTag) => existingTag.id === tag.id)
          ? existingTags
          : [...existingTags, tag]
        : existingTags.filter((existingTag) => existingTag.id !== tag.id)
      updateCalendar({
        input: {
          id: calendar.id,
          patch: {
            defaultTags: newTags.map((tag) => tag.id),
          },
        },
      })
    },
    [updateCalendar],
  )

  const areaMenu = useAreasMenu({
    selectionMode: "radio",
    showNoAreaOption: true,
    showAreaEditMenu: false,
  })

  const tagsMenu = useTagsMenu({
    partitioned: true,
    selectionMode: "checkbox",
    showNoTagOption: false,
    showTagEditMenu: false,
  })

  const optionsFor = useCallback(
    (
      calendar: Calendar,
      order: string[],
    ): (MenuItem | MenuItemGroup | React.ReactElement)[] => {
      const currentUserParticipant = calendar.participants?.find(
        (participant) =>
          user?.id !== undefined &&
          participant.type === "user" &&
          participant.user.id === user.id,
      )

      return [
        ...(currentUserParticipant?.status === CalendarParticipantStatus.Pending
          ? [
              {
                groupId: "accept-reject",
                items: [
                  {
                    id: "accept",
                    title: t("accept_invitation"),
                    icon: "Tick",
                    hue: 120,
                    onSelect: () => {
                      void updateCalendar({
                        input: {
                          id: calendar.id,
                          patch: {
                            participants: [
                              {
                                id: currentUserParticipant.id,
                                status: CalendarParticipantStatus.Accepted,
                              },
                            ],
                          },
                        },
                      })
                    },
                  },
                  {
                    id: "reject",
                    title: t("decline_invitation"),
                    icon: "Cross",
                    hue: 0,
                    onSelect: () => {
                      void updateCalendar({
                        input: {
                          id: calendar.id,
                          patch: {
                            participants: [
                              {
                                id: currentUserParticipant.id,
                                status: CalendarParticipantStatus.Declined,
                              },
                            ],
                          },
                        },
                      })
                    },
                  },
                ],
              },
            ]
          : []),
        ...(permissionsFor(calendar).canRenameForEveryone ||
        permissionsFor(calendar).canRenameForSelf
          ? [
              {
                id: "rename",
                title: t("rename"),
                icon: "Pencil",
                onSelect: () => {
                  setRenaming(calendar.id)
                  return false
                },
              },
            ]
          : []),
        ...(permissionsFor(calendar).canSeeParticipants
          ? [
              {
                id: "calendar_sharing",
                icon: "Users",
                title: t(
                  permissionsFor(calendar).canInviteParticipant
                    ? calendar.participants === undefined ||
                      calendar.participants.length <= 1
                      ? "share"
                      : "manage_sharing"
                    : "see_members",
                ),
                onSelect: () => {
                  beginFlow({
                    id: "calendar_edit",
                    params: {
                      calendar,
                    },
                  })
                },
              },
            ]
          : []),
        ...(permissionsFor(calendar).canModifyTimeZone
          ? [
              {
                id: "settings",
                icon: "Settings",
                title: t("settings"),
                onSelect: () => {
                  beginFlow({
                    id: "calendar_edit",
                    params: {
                      calendar,
                    },
                  })
                },
              },
            ]
          : []),
        {
          groupId: "assign-to-area-tags",
          items: [
            ...(permissionsFor(calendar).canModifyDefaultArea
              ? [
                  {
                    id: "assign-to-area",
                    title: t("assign_to_area"),
                    icon: "Area",
                    expandChildrenAs: {
                      mode: "combobox" as const,
                      component: Combobox,
                    },
                    children: areaMenu({
                      noAreaOptionSelected: !calendar.defaultArea,
                      noAreaText: calendar.account?.defaultArea
                        ? t("use_account_default_area", {
                            areaName: calendar.account?.defaultArea?.name,
                          })
                        : undefined,
                      noAreaIcon: calendar.account?.defaultArea?.icon,
                      onNoAreaChange: (selected) => {
                        if (selected) {
                          updateCalendar({
                            input: {
                              id: calendar.id,
                              patch: {
                                defaultArea: null,
                              },
                            },
                          })
                        }
                      },
                      selectedAreas: calendar.defaultArea
                        ? [calendar.defaultArea.id]
                        : [],
                      onAreaSelect: (area) =>
                        updateCalendar({
                          input: {
                            id: calendar.id,
                            patch: {
                              defaultArea: area.id,
                            },
                          },
                        }),
                    }),
                  },
                ]
              : []),
            ...(permissionsFor(calendar).canModifyDefaultTags
              ? [
                  {
                    id: "default-tags",
                    title: t("default_tags"),
                    icon: "Tag",
                    expandChildrenAs: {
                      mode: "combobox" as const,
                      component: Combobox,
                    },
                    children: tagsMenu({
                      selectedTags: calendar.defaultTags.map((tag) => tag.id),
                      onTagSelect: (tag, selected) =>
                        onTagSelect(calendar, tag, selected),
                      allowReorder: true,
                    }),
                  },
                ]
              : []),
          ],
        },
        ...(currentUserParticipant &&
        permissionsFor(calendar).canLeave(currentUserParticipant)
          ? [
              {
                id: "leave",
                icon: "SignOut",
                title: t("leave"),
                onSelect: () => {
                  if (!user?.id) {
                    return
                  }

                  updateCalendar({
                    input: {
                      id: calendar.id,
                      patch: {
                        participants: [
                          {
                            id: currentUserParticipant.id,
                            status: CalendarParticipantStatus.Declined,
                          },
                        ],
                      },
                    },
                  })
                },
              },
            ]
          : []),
        {
          groupId: "move_up_down",
          items: [
            {
              id: "move_up",
              title: t("move_up"),
              icon: "ArrowUp",
              onSelect: () => {
                moveItemUp(order, calendar.id, calendar.account?.id)
              },
            },
            {
              id: "move_down",
              title: t("move_down"),
              icon: "ArrowDown",
              onSelect: () => {
                moveItemDown(order, calendar.id, calendar.account?.id)
              },
            },
          ],
        },
        ...(permissionsFor(calendar).canDelete
          ? [
              {
                id: "delete",
                icon: "TrashEmpty",
                title: t("delete"),
                onSelect: () => {
                  beginFlow({
                    id: "calendar_deletion",
                    params: { calendar: calendar },
                  })
                },
              },
            ]
          : []),
        ...(calendar.enabled &&
        calendar.account?.providerId !== OAuthProviderId.Daybridge
          ? [
              {
                id: "disable",
                icon: "Cross",
                hue: 0,
                title: t("disable"),
                description: t("disable_description"),
                onSelect: () => {
                  updateCalendar({
                    input: {
                      id: calendar.id,
                      patch: {
                        enabled: false,
                      },
                    },
                  })
                },
              },
            ]
          : []),
      ]
    },
    [
      t,
      user,
      permissionsFor,
      beginFlow,
      updateCalendar,
      tagsMenu,
      onTagSelect,
      areaMenu,
      moveItemUp,
      moveItemDown,
    ],
  )

  const sharingDescription = useCalendarSharingDescription()

  const items = useCallback(
    (opts?: CalendarMenuInstanceOptions): MenuPage["items"] => {
      if (
        options?.account &&
        (!groupedCalendars || groupedCalendars[0].calendars.length === 0)
      ) {
        return []
      }

      return [
        ...(groupedCalendars
          ? groupedCalendars.map((group): MenuItemGroup => {
              const providerTitle =
                group.providerId === OAuthProviderId.Daybridge
                  ? "Daybridge"
                  : (group as GoogleCalendarProvider).customName ??
                    group.username
              return {
                groupId: group.id,
                collapsible: !options?.account,
                selectionMode: options?.selectionMode,
                icon: !options?.account ? group.icon : undefined,
                title: !options?.account ? providerTitle : undefined,
                onReorder: (group, order) => {
                  reorderCalendars(order, group)
                },
                items: group.calendars.map((calendar): MenuItem => {
                  const currentUserParticipant = calendar.participants?.find(
                    (participant) =>
                      user?.id !== undefined &&
                      participant.type === "user" &&
                      participant.user.id === user.id,
                  )

                  const disabled = opts?.isSelectable
                    ? !opts?.isSelectable?.(calendar)
                    : false
                  const selected = opts?.selectedCalendarIds?.includes(
                    calendar.id,
                  )

                  return {
                    id: calendar.id,
                    title: calendar.customName || calendar.name,
                    description: sharingDescription(calendar),
                    disabled,
                    strikethrough: !calendar.enabled,
                    onSelect: () => {
                      if (opts?.onCalendarSelect) {
                        opts.onCalendarSelect(calendar)
                      }
                    },
                    selected,
                    renaming: renaming === calendar.id,
                    onRename: (newName) => onRename(calendar, newName),
                    onRenameCancel: () => setRenaming(undefined),
                    allowEmptyName:
                      !permissionsFor(calendar).canRenameForEveryone,
                    maxNameLength: 64,
                    expandChildrenAs: {
                      mode: "menu",
                      component: Dropdown,
                    },
                    rightContent:
                      currentUserParticipant?.status ===
                      CalendarParticipantStatus.Pending ? (
                        <Label
                          theme="neutral"
                          icon={IconId.Envelope}
                          className="ml-4 mr-1 text-sm"
                        >
                          {t("invited")}
                        </Label>
                      ) : calendar.enabled ? (
                        <AreaLabel
                          area={
                            calendar.defaultArea ??
                            calendar.account?.defaultArea
                          }
                          inherited={
                            !!calendar.account?.defaultArea &&
                            !calendar.defaultArea
                          }
                          className="ml-4 mr-1 max-w-32"
                        />
                      ) : undefined,
                    children: calendar.enabled
                      ? {
                          breadcrumbHue: calendar.defaultArea?.hue,
                          items: optionsFor(
                            calendar,
                            group.calendars.map((c) => c.id),
                          ),
                        }
                      : undefined,
                  }
                }),
              }
            })
          : []),
      ]
    },
    [
      groupedCalendars,
      options,
      optionsFor,
      onRename,
      renaming,
      setRenaming,
      reorderCalendars,
      sharingDescription,
      permissionsFor,
      t,
      user,
    ],
  )

  let emptyStateTitle = t("no_calendars")
  let emptyStateDescription = t("no_calendars_description")
  if (
    options?.account &&
    options.account.providerId !== OAuthProviderId.Daybridge
  ) {
    switch (options.account.status) {
      case OAuthConnectionStatus.Syncing:
      case OAuthConnectionStatus.Creating: {
        emptyStateTitle = t("syncing_calendars")
        emptyStateDescription = t("syncing_calendars_description")
        break
      }
      case OAuthConnectionStatus.RequiresReconnection: {
        emptyStateTitle = t("sync_failed")
        emptyStateDescription = t("sync_failed_description")
        break
      }
      default: {
        emptyStateTitle = t("no_calendars")
        emptyStateDescription = t("no_calendars_in_connected_account")
      }
    }
  }

  return useCallback(
    (opts?: CalendarMenuInstanceOptions): MenuPage => ({
      prompt: t("search_calendars"),
      noResultsIcon: "Calendar",
      emptyStateTitle,
      emptyStateDescription,
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      items: items(opts),
    }),
    [items, tNoResults, t, emptyStateTitle, emptyStateDescription],
  )
}
