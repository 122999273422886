import {
  createContext,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from "react"
import { Calendar } from "../../../../data/calendars/Calendar"
import { FeedbackType } from "../../../../data/_gen/types"

export type Flow =
  | {
      // Keyboard shortcut cheat sheet
      id: "keyboard_shortcuts"
      params?: never
    }
  | {
      // Command palette. Accepts callbacks for onOpenChange and onPageChange.
      id: "command_k"
    }
  | {
      // Feedback and support form
      id: "feedback_triage"
    }
  | {
      id: "feedback_form"
      params: {
        feedbackType: FeedbackType
      }
    }
  | {
      id: "item_multi_select"
      // Params are handled by the ItemMultiSelectProvider.
      // Only the active/inactive state is handled by the FlowProvider
      params?: never
    }
  | {
      id: "calendar_creation"
    }
  | {
      id: "calendar_edit"
      params: {
        calendar: Calendar
      }
    }
  | {
      id: "calendar_deletion"
      params: {
        calendar: Calendar
      }
    }

export type FlowContext = Flow | undefined
export type FlowAction =
  | {
      type: "begin"
      flow: Flow
    }
  | {
      type: "end"
    }

// Flow Context — used to read the current flow state
const flowContext = createContext<FlowContext>(undefined)
const FlowContextProvider = flowContext.Provider
export const useFlow = () => useContext(flowContext)

// Flow Dispatch Context — used to update the flow
const flowDispatchContext = createContext<React.Dispatch<FlowAction>>(() => {
  throw new Error("FlowDispatchContext not set")
})
const FlowDispatchContextProvider = flowDispatchContext.Provider
export const useUpdateFlow = () => useContext(flowDispatchContext)

export const useBeginFlow = () => {
  const currentFlow = useFlow()
  const dispatch = useUpdateFlow()
  return useCallback(
    (flow: Flow) => {
      if (currentFlow) {
        // Close the flow, then allow some time for animations to complete
        // before opening the new flow.
        dispatch({
          type: "end",
        })
        setTimeout(() => {
          dispatch({
            type: "begin",
            flow,
          })
        }, 200)
      } else {
        dispatch({
          type: "begin",
          flow,
        })
      }
    },
    [dispatch, currentFlow],
  )
}

export const useEndFlow = () => {
  const dispatch = useUpdateFlow()
  return () => {
    dispatch({
      type: "end",
    })
  }
}

export const FlowProvider = ({ children }: { children: React.ReactNode }) => {
  const [flow, dispatch] = useReducer<Reducer<Flow | undefined, FlowAction>>(
    (state, action) => {
      switch (action.type) {
        case "begin":
          return action.flow
        case "end":
          return undefined
        default:
          return state
      }
    },
    undefined,
  )

  return (
    <FlowContextProvider value={flow}>
      <FlowDispatchContextProvider value={dispatch}>
        {children}
      </FlowDispatchContextProvider>
    </FlowContextProvider>
  )
}
