import { useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"
import { useToast } from "@daybridge/components"
import {
  useAccountQuery,
  useResyncOAuthConnectionMutation,
} from "../_gen/hooks"

export const useResyncOAuthConnection = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutate } = useResyncOAuthConnectionMutation({
    onSuccess: useCallback(async () => {
      await queryClient.invalidateQueries(useAccountQuery.getKey())
      toast.success({
        // TODO TRANSLATION
        icon: "Repeat",
        title: "Sync started",
      })
    }, [queryClient, toast]),
  })

  return useCallback(
    (connectionId: string) => {
      mutate({
        input: {
          id: connectionId,
        },
      })
    },
    [mutate],
  )
}
