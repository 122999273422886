import { cn } from "@daybridge/cn"
import { Icon } from "@daybridge/components"
import { uniqueId } from "lodash"
import React, { createContext, useContext, useState } from "react"

/**
 * Create a context for the InputGroup component.
 * This context is used to pass the label and description IDs to the Input
 * component.
 */
export type InputGroupContext = {
  labelId?: string
  descriptionId?: string
}
const inputGroupContext = createContext<InputGroupContext>({})
export const InputGroupProvider = inputGroupContext.Provider
export const useInputGroup = () => useContext(inputGroupContext)

type InputGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  title: React.ReactNode
  error?: string
  touched?: boolean
  description?: React.ReactNode
}

const InputGroupFn = React.forwardRef(
  (props: InputGroupProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, title, description, error, touched, ...rest } =
      props
    const [labelledBy] = useState<string>(uniqueId("label"))
    const [describedBy] = useState<string>(uniqueId("description"))

    return (
      <InputGroupProvider
        value={{
          labelId: labelledBy,
          descriptionId: describedBy,
        }}
      >
        <div
          ref={ref}
          className={cn(
            "w-full",
            "font-medium leading-normal",
            "mb-10",
            "flex flex-col",
            "space-y-4",
            className,
          )}
          {...rest}
        >
          <div className={cn("flex flex-col flex-1")}>
            <div
              className={cn("title text-high-contrast font-medium")}
              id={labelledBy}
            >
              {props.title}
            </div>
            {description && (
              <div
                id={describedBy}
                className={cn(
                  "text-[0.9em]",
                  "text-medium-contrast",
                  "text-balance",
                  "mt-1",
                )}
              >
                {description}
              </div>
            )}
          </div>
          <div className="flex-shrink-0 flex flex-col items-start">
            {children}
            {touched && error && (
              <div
                className={cn("text-sm text-state-error-medium-contrast mt-2")}
              >
                <Icon
                  name="Warning"
                  className={cn("w-3 h-3", "inline", "mx-1")}
                />
                {error}
              </div>
            )}
          </div>
        </div>
      </InputGroupProvider>
    )
  },
)
InputGroupFn.displayName = "InputGroup"

export const InputGroup = React.memo(InputGroupFn) as typeof InputGroupFn
