import { useAccount } from "../account/useAccount"
import {
  CalendarParticipantRole,
  CalendarParticipantStatus,
  OAuthProviderId,
} from "../_gen/types"
import { Calendar, CalendarParticipant } from "./Calendar"

export type CalendarPermissions = {
  isMember: boolean
  hasAccepted: boolean

  // Calendar items
  canCreateItems: boolean
  canModifyItems: boolean

  // Calendar name
  canRenameForSelf: boolean
  canRenameForEveryone: boolean

  // Calendar properties
  canModifyTimeZone: boolean
  canModifyDefaultArea: boolean
  canModifyDefaultTags: boolean
  canModifyDefaultAlertSettings: boolean

  // Participant management
  canSeeParticipants: boolean
  canInviteParticipant: boolean
  canRemoveParticipant: (participant: CalendarParticipant) => boolean
  canChangeParticipantRole: (participant: CalendarParticipant) => boolean
  canLeave: (participant: CalendarParticipant) => boolean

  // Deletion
  canDelete: boolean
}

/**
 * `useCalendarPermissions` is a hook that returns the permissions of a user
 * for a given calendar based on their role and the calendar type and settings.
 * @param calendar The calendar for which to check permissions.
 * @param userId The ID of the user for which to check permissions. If not provided, the current user is assumed.
 * @returns An object containing the permissions of the current user for the given calendar.
 */
export const useCalendarPermissions = () => {
  const { data: account } = useAccount()
  return (calendar: Calendar, userIdOverride?: string): CalendarPermissions => {
    if (!account) {
      throw new Error(
        "Unable to check calendar permissions because account was missing",
      )
    }
    const { id: accountId } = account

    // Use the provided userId or default to the current user's ID
    const userId = userIdOverride || accountId

    // First find the current user's entry in the list of calendar participants
    const participant = calendar.participants?.find(
      (participant) =>
        userId !== undefined &&
        participant.type === "user" &&
        participant.user.id === userId,
    )
    const role = participant?.role
    const hasAccepted =
      participant?.status === CalendarParticipantStatus.Accepted
    const calendarIsDaybridgeCalendar =
      calendar.account?.providerId === OAuthProviderId.Daybridge

    const owners =
      calendar.participants?.filter(
        (participant) =>
          calendarRoleAtLeast(
            participant.role,
            CalendarParticipantRole.Owner,
          ) && participant.status === CalendarParticipantStatus.Accepted,
      ) || []

    // If no permission info is found or the user hasn't accepted the invitation,
    // their permissions are restricted. This scenario is expected to happen when
    // the user receives a direct event invitation - they won't be a member of the
    // calendar that the item is in.
    if (!role || !hasAccepted) {
      return {
        isMember: !!role,
        hasAccepted: false,
        canCreateItems: false,
        canModifyItems: false,
        canRenameForSelf: false,
        canRenameForEveryone: false,
        canModifyTimeZone: false,
        canModifyDefaultArea: false,
        canModifyDefaultTags: false,
        canModifyDefaultAlertSettings: false,
        canInviteParticipant: false,
        canSeeParticipants: true, // Everyone can see participants even if they haven't accepted
        canRemoveParticipant: () => false,
        canChangeParticipantRole: () => false,
        canLeave: () => false,
        canDelete: false,
      }
    }

    // To create items, you need to be at least an editor and have accepted the invitation
    // to join the calendar
    const canCreateItems = calendarRoleAtLeast(
      role,
      CalendarParticipantRole.Editor,
    )

    // To edit items, you need to be at least an editor and have accepted the invitation
    // to join the calendar
    const canModifyItems = calendarRoleAtLeast(
      role,
      CalendarParticipantRole.Editor,
    )

    // Anyone can rename the calendar for themselves if they have accepted the invitation
    const canRenameForSelf = true

    // Only owners can rename the calendar for everyone if they have accepted the invitation
    const canRenameForEveryone = calendarRoleAtLeast(
      role,
      CalendarParticipantRole.Owner,
    )

    // Only owners can modify the calendar's default time zone
    const canModifyTimeZone = calendarRoleAtLeast(
      role,
      CalendarParticipantRole.Owner,
    )

    // Since these properties are user-level, they can always be modified
    const canModifyDefaultArea = true
    const canModifyDefaultTags = true
    const canModifyDefaultAlertSettings = true

    // Everyone can see participants
    const canSeeParticipants = true

    // Only owners can invite participants
    const canInviteParticipant =
      calendarIsDaybridgeCalendar &&
      calendarRoleAtLeast(role, CalendarParticipantRole.Owner)

    const canRemoveParticipant = (participant: CalendarParticipant) =>
      // You can only remove participants from Daybridge calendars
      calendarIsDaybridgeCalendar &&
      // You need to be an owner to remove someone
      calendarRoleAtLeast(role, CalendarParticipantRole.Owner) &&
      // You can't remove yourself (this is "leaving" which is treated separately)
      !(participant.type === "user" && participant.user.id === userId)

    const canChangeParticipantRole = (participant: CalendarParticipant) =>
      // You can only change participant roles in Daybridge calendars
      calendarIsDaybridgeCalendar &&
      // You need to be an owner to change participant roles
      calendarRoleAtLeast(role, CalendarParticipantRole.Owner) &&
      // You can't change your own role
      !(participant.type === "user" && participant.user.id === userId)

    const canLeave = (participant: CalendarParticipant) =>
      // To "Leave" you need to have accepted. Otherwise you're declining.
      hasAccepted &&
      calendar.account?.providerId === OAuthProviderId.Daybridge &&
      // You can't leave a calendar that has no other participants
      (calendar.participants || []).length > 1 &&
      // People cannot leave if it would mean the calendar would have no owner
      (!calendarRoleAtLeast(role, CalendarParticipantRole.Owner) ||
        owners.length > 1) &&
      // You can't "leave" somebody else
      participant.type === "user" &&
      participant.user.id === userId

    // Only Daybridge calendars can be deleted and only by owners
    const canDelete =
      calendarIsDaybridgeCalendar &&
      calendarRoleAtLeast(role, CalendarParticipantRole.Owner)

    return {
      isMember: true,
      hasAccepted,
      canCreateItems,
      canModifyItems,

      canRenameForSelf,
      canRenameForEveryone,

      canModifyTimeZone,
      canModifyDefaultArea,
      canModifyDefaultTags,
      canModifyDefaultAlertSettings,

      canSeeParticipants,
      canInviteParticipant,
      canRemoveParticipant,
      canChangeParticipantRole,

      canLeave,
      canDelete,
    }
  }
}

export const calendarRoleAtLeast = (
  role: CalendarParticipantRole,
  atLeast: CalendarParticipantRole,
): boolean => {
  switch (atLeast) {
    case CalendarParticipantRole.Reader:
      return (
        role === CalendarParticipantRole.Reader ||
        role === CalendarParticipantRole.Editor ||
        role === CalendarParticipantRole.Owner
      )
    case CalendarParticipantRole.Editor:
      return (
        role === CalendarParticipantRole.Editor ||
        role === CalendarParticipantRole.Owner
      )
    case CalendarParticipantRole.Owner:
      return role === CalendarParticipantRole.Owner
    default:
      return false
  }
}
