import { MenuItem } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useMemo } from "react"

export const useCreateAreaMenu = (onSelect: () => void): MenuItem => {
  const t = useTranslations("areas")

  return useMemo(
    (): MenuItem => ({
      id: "create-area",
      title: t("create_area"),
      icon: "Plus",
      onSelect,
    }),
    [t, onSelect],
  )
}
