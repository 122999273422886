import { useRef, useImperativeHandle } from "react"

/**
 * useRefWithBackup is a custom hook that creates a new React ref and syncs it
 * with the provided ref (which might be a forwarded ref from a parent component).
 * The purpose of this hook is to ensure that we always have a ref that we can use,
 * even if the parent component doesn't provide one.
 *
 * @param {React.Ref<T | null> | undefined} ref - The ref that is provided by the parent component
 * @returns {React.MutableRefObject<T | null>} - The resolved ref, which is either the provided ref or the new backup ref
 */
export function useRefWithBackup<T = unknown>(
  ref: React.Ref<T | null> | undefined,
): React.MutableRefObject<T | null> {
  // Backup ref in case no ref is provided by the parent component
  const backupRef = useRef<T | null>(null)

  // The useImperativeHandle hook allows for customizing the ref value that gets exposed to parent components
  useImperativeHandle(ref, () => backupRef.current, [backupRef])

  // Return the resolved ref (which is the backup ref)
  return backupRef
}
