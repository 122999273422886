import { MenuItem, MenuItemGroup, MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback } from "react"
import { useIcons } from "../../data/icons/useIcons"
import { IconId } from "../../data/_gen/types"

export const useIconsMenu = (): ((
  selectedIconIds?: IconId[],
  onIconSelect?: (iconId: IconId | undefined) => void,
  allowRemove?: boolean,
) => MenuPage) => {
  const t = useTranslations("icons")
  const { data: icons } = useIcons()

  return useCallback(
    (selectedIconIds, onIconSelect, allowRemove) => {
      return {
        prompt: t("search_icons"),
        defaultHighlightedValue: selectedIconIds?.[0],
        items: [
          ...(allowRemove
            ? [
                {
                  id: "remove",
                  title: `(${t("no_icon")})`,
                  icon: "Minus",
                  onSelect: () => onIconSelect?.(undefined),
                  maxSearchDepth: 0,
                },
              ]
            : []),
          ...(icons?.map(
            (g): MenuItemGroup => ({
              groupId: g.id,
              selectionMode: "radio",
              collapsible: true,
              title: g.name,
              icon: g.icon.id,
              items: g.icons.map(
                (icon): MenuItem => ({
                  id: icon.id,
                  title: icon.name,
                  icon: icon.id,
                  maxSearchDepth: 0,
                  selected: selectedIconIds?.includes(icon.id) || false,
                  onSelect: () => onIconSelect?.(icon.id),
                  searchStrings: icon.queryStrings,
                }),
              ),
            }),
          ) || []),
        ],
      }
    },
    [icons, t],
  )
}
