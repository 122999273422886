import {
  sortObjects,
  useOptimisticMutations,
  useOptimisticQuery,
} from "@daybridge/optimism"
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { DateTime } from "luxon"
import {
  CalendarAccountsQuery,
  CalendarAccountsQueryVariables,
  CreateCalendarMutation,
  CreateCalendarMutationVariables,
  DeleteCalendarMutation,
  DeleteCalendarMutationVariables,
  ReorderCalendarsMutation,
  ReorderCalendarsMutationVariables,
  UpdateCalendarMutation,
  UpdateCalendarMutationVariables,
} from "../_gen/operations"
import { GraphQLError } from "../../lib/graphql/errors"
import useFetcher from "../../lib/graphql/fetcher"
import {
  CalendarAccountsDocument,
  CreateCalendarDocument,
  DeleteCalendarDocument,
  ReorderCalendarsDocument,
  UpdateCalendarDocument,
} from "../_gen/hooks"
import { useTimeZone } from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"
import { OAuthConnectionStatus, OAuthProviderId } from "../_gen/types"
import { useAreas } from "../areas/useAreas"
import { useTags } from "../tags/useTags"
import { Tag } from "../tags/Tag"
import { useGeoData } from "../geodata/useGeoData"
import { Calendar, CalendarAccount, GoogleCalendarProvider } from "./Calendar"
import { calendarAccountFromGraphQL, calendarFromGraphQL } from "./marshalling"

const getPageInfo = (data: CalendarAccountsQuery) => {
  return data.calendarAccounts?.pageInfo
}

export const useCalendars = (
  includeDisabled?: boolean,
  variables?: CalendarAccountsQueryVariables,
  options?: UseInfiniteQueryOptions<
    CalendarAccountsQuery,
    GraphQLError,
    CalendarAccount[]
  >,
) => {
  const { effective } = useTimeZone()
  const { data: areas } = useAreas()
  const { data: tags } = useTags()
  const { data: geodata } = useGeoData()
  const timeZones = useMemo(() => geodata?.timeZones, [geodata])

  // Marshalling
  const fromNetworkObject = useCallback(
    (
      account: NonNullable<
        CalendarAccountsQuery["calendarAccounts"]
      >["edges"][number]["node"],
    ): CalendarAccount => {
      const calendars = account.calendars.edges
        .map((c) => {
          if (
            !includeDisabled &&
            c.node.__typename !== "DaybridgeCalendar" &&
            !c.node.enabled
          ) {
            return null
          }
          return calendarFromGraphQL(c.node, effective)
        })
        .filter((c): c is Calendar => !!c)

      return calendarAccountFromGraphQL(account, calendars)
    },
    [effective, includeDisabled],
  )

  const marshalAccounts = useCallback(
    (data: InfiniteData<CalendarAccountsQuery>): CalendarAccount[] => {
      return data.pages?.flatMap(
        (page) =>
          page.calendarAccounts?.edges.map((edge) => {
            return fromNetworkObject(edge.node)
          }) || [],
      )
    },
    [fromNetworkObject],
  )

  // Creation
  const createTemporaryDomainObject = useCallback(
    (
      variables: CreateCalendarMutationVariables,
      accounts: CalendarAccount[],
    ): Calendar => {
      const daybridgeAccount = accounts.find(
        (account) => account.providerId === OAuthProviderId.Daybridge,
      )

      if (!daybridgeAccount) {
        throw new Error("Daybridge account not found")
      }

      const now = DateTime.now().setZone(effective)

      return {
        id: variables.input.idempotencyKey,
        name: variables.input.create.name,
        account: daybridgeAccount,
        enabled: true,
        defaultTags:
          variables.input.create.defaultTags
            ?.map((tag) => {
              const foundTag = tags?.find((t) => t.id === tag)
              if (!foundTag) return undefined
              return foundTag
            })
            .filter((tag): tag is Tag => !!tag) || [],
        defaultArea: areas?.find(
          (area) => area.id === variables.input.create.defaultArea,
        ),
        defaultEventAlerts:
          (variables.input.create
            .defaultEventAlerts as Calendar["defaultEventAlerts"]) || [],
        defaultAllDayEventAlerts:
          (variables.input.create
            .defaultAllDayEventAlerts as Calendar["defaultAllDayEventAlerts"]) ||
          [],
        createdAt: now,
        updatedAt: now,
      }
    },
    [effective, tags, areas],
  )

  const applyTemporaryDomainObject = useCallback(
    (calendar: Calendar, accounts: CalendarAccount[]): CalendarAccount[] => {
      const daybridgeAccount = accounts.find(
        (account) => account.providerId === OAuthProviderId.Daybridge,
      )

      if (!daybridgeAccount) {
        throw new Error("Daybridge account not found")
      }

      const newDaybridgeAccount = {
        ...daybridgeAccount,
        calendars: [...daybridgeAccount.calendars, calendar],
      }

      return accounts.map((account) =>
        account.providerId === OAuthProviderId.Daybridge
          ? newDaybridgeAccount
          : account,
      )
    },
    [],
  )

  const findDomainObjectToPatch = useCallback(
    (accounts: CalendarAccount[], patch: UpdateCalendarMutationVariables) => {
      return accounts.findIndex((account) =>
        account.calendars.some((calendar) => calendar.id === patch.input.id),
      )
    },
    [],
  )

  const applyPatch = useCallback(
    (account: CalendarAccount, patch: UpdateCalendarMutationVariables) => {
      const calendar = account.calendars.find((c) => c.id === patch.input.id)
      if (!calendar) {
        return account
      }

      const updatedCalendar: Calendar = {
        ...calendar,
        name: patch.input.patch.name ?? calendar.name,
        customName: patch.input.patch.customName ?? calendar.customName,
        enabled: patch.input.patch.enabled ?? calendar.enabled,
        timeZone: patch.input.patch.timeZone
          ? timeZones?.[patch.input.patch.timeZone] ?? calendar.timeZone
          : calendar.timeZone,
        defaultArea: patch.input.patch.defaultArea
          ? areas?.find((area) => area.id === patch.input.patch.defaultArea)
          : calendar.defaultArea,
        defaultTags: patch.input.patch.defaultTags
          ? patch.input.patch.defaultTags
              .map((tag) => {
                const foundTag = tags?.find((t) => t.id === tag)
                if (!foundTag) return undefined
                return foundTag
              })
              .filter((tag): tag is Tag => !!tag)
          : calendar.defaultTags,
        defaultEventAlerts:
          (patch.input.patch
            .defaultEventAlerts as Calendar["defaultEventAlerts"]) ??
          calendar.defaultEventAlerts,
        defaultAllDayEventAlerts:
          (patch.input.patch
            .defaultAllDayEventAlerts as Calendar["defaultAllDayEventAlerts"]) ??
          calendar.defaultAllDayEventAlerts,
      }

      const updatedCalendars = account.calendars.map((c) =>
        c.id === updatedCalendar.id ? updatedCalendar : c,
      )

      return {
        ...account,
        calendars: updatedCalendars,
      }
    },
    [areas, tags, timeZones],
  )

  const deleteObject = useCallback(
    (accounts: CalendarAccount[], id: string) => {
      return accounts.map((account) => {
        return {
          ...account,
          calendars: account.calendars.filter((calendar) => calendar.id !== id),
        }
      })
    },
    [],
  )

  const refetchInterval = useCallback(
    (data: InfiniteData<CalendarAccount[]> | undefined) => {
      if (
        data?.pages
          ?.flatMap((page) => page)
          .filter(
            (
              provider,
            ): provider is GoogleCalendarProvider & { calendars: Calendar[] } =>
              provider.providerId !== OAuthProviderId.Daybridge,
          )
          .some(
            (connection) =>
              connection.status === OAuthConnectionStatus.Syncing ||
              connection.status === OAuthConnectionStatus.Creating,
          )
      ) {
        return 5000
      }
      return false
    },
    [],
  )

  const sort = useCallback(
    (
      accounts: CalendarAccount[],
      order: string[],
      idKey: string,
      group?: string,
    ) => {
      if (order.length === 0) return accounts
      const accountIndex = accounts.findIndex((a) => a.id === group)
      if (accountIndex === -1) return accounts
      const account = accounts[accountIndex]

      // call sortObjects(account.calendars, order, "id") to sort the calendars
      const sortedCalendars = sortObjects(account.calendars, order, "id")

      // return a new array with the sorted calendars
      return accounts.map((a, i) =>
        i === accountIndex ? { ...account, calendars: sortedCalendars } : a,
      )
    },
    [],
  )

  const mergedOptions: UseInfiniteQueryOptions<
    CalendarAccountsQuery,
    GraphQLError,
    CalendarAccount[]
  > = useMemo(() => {
    return {
      refetchInterval,
      staleTime: 0,
      ...options,
    }
  }, [options, refetchInterval])

  return useOptimisticQuery({
    queryName: "CalendarAccounts",
    variables,
    fetcher: useFetcher<CalendarAccountsQuery, CalendarAccountsQueryVariables>(
      CalendarAccountsDocument,
    ).bind(null, variables),
    fromNetworkObject: marshalAccounts,
    options: mergedOptions,
    getPageInfo,
    createTemporaryDomainObject,
    applyTemporaryDomainObject,
    findDomainObjectToPatch,
    applyPatch,
    deleteObject,
    sort,
  })
}

const useReorder = () => {
  const fetcher = useFetcher<
    ReorderCalendarsMutation,
    ReorderCalendarsMutationVariables
  >(ReorderCalendarsDocument)

  return useCallback(
    (vars: { input: { sortOrder: string[]; group?: string } }) =>
      fetcher(vars.input as Required<typeof vars.input>),
    [fetcher],
  )
}

export const useCalendarOperations = () =>
  useOptimisticMutations({
    queryName: "CalendarAccounts",
    additionalInvalidationKeys: [["Items"], ["OAuthConnections"]],
    create: {
      fetcher: useFetcher<
        CreateCalendarMutation,
        CreateCalendarMutationVariables
      >(CreateCalendarDocument),
    },
    update: {
      fetcher: useFetcher<
        UpdateCalendarMutation,
        UpdateCalendarMutationVariables
      >(UpdateCalendarDocument),
    },
    delete: {
      fetcher: useFetcher<
        DeleteCalendarMutation,
        DeleteCalendarMutationVariables
      >(DeleteCalendarDocument),
    },
    reorder: {
      fetcher: useReorder(),
    },
  })
