import { useOptimisticMutations, useOptimisticQuery } from "@daybridge/optimism"
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query"
import { GraphQLError } from "graphql-request/dist/types"
import { useMemo } from "react"
import useFetcher from "../../lib/graphql/fetcher"
import {
  DeleteOAuthConnectionDocument,
  OAuthConnectionsDocument,
  UpdateOAuthConnectionDocument,
  useCalendarAccountsQuery,
} from "../_gen/hooks"
import {
  DeleteOAuthConnectionMutationVariables,
  UpdateOAuthConnectionMutationVariables,
  DeleteOAuthConnectionMutation,
  UpdateOAuthConnectionMutation,
  OAuthConnectionsQuery,
  OAuthConnectionsQueryVariables,
} from "../_gen/operations"
import { OAuthConnectionStatus } from "../_gen/types"
import { areaFromGraphQL } from "../areas/useAreas"
import { OAuthConnection } from "./oauth-connection"

export const oAuthConnectionFromGraphQL = (
  connection: NonNullable<
    OAuthConnectionsQuery["currentAccount"]
  >["oAuthConnections"]["edges"][number]["node"],
): OAuthConnection => {
  return {
    id: connection.id,
    accountId: connection.accountId,
    providerId: connection.providerId,
    providerName: connection.providerName,
    customName: connection.customName || undefined,
    email: connection.email || undefined,
    defaultArea:
      connection.__typename === "GoogleOAuthConnection"
        ? connection.defaultArea
          ? areaFromGraphQL(connection.defaultArea)
          : undefined
        : undefined,
    scopes: connection.scopes,
    status: connection.status,
  }
}

const marshalOAuthConnections = (
  data: InfiniteData<OAuthConnectionsQuery>,
): OAuthConnection[] =>
  data.pages.flatMap(
    (page) =>
      page.currentAccount?.oAuthConnections?.edges.map((edge) => {
        return oAuthConnectionFromGraphQL(edge.node)
      }) || [],
  ) || []

const applyOAuthConnectionPatch = (
  oAuthConnection: OAuthConnection,
  vars: UpdateOAuthConnectionMutationVariables,
) => {
  const patch = vars.input.patch
  if (!patch) return oAuthConnection
  const { customName } = patch

  return {
    ...oAuthConnection,
    customName:
      customName === "" ? undefined : customName ?? oAuthConnection.customName,
  }
}

const refetchInterval = (data: InfiniteData<OAuthConnection[]> | undefined) => {
  if (
    data?.pages
      .flatMap((page) => page)
      .some(
        (connection) =>
          connection.status === OAuthConnectionStatus.Syncing ||
          connection.status === OAuthConnectionStatus.Creating,
      )
  ) {
    return 5000
  }
  return false
}

export const useOAuthConnections = (
  variables?: OAuthConnectionsQueryVariables,
  options?: UseInfiniteQueryOptions<
    OAuthConnectionsQuery,
    GraphQLError,
    OAuthConnection[]
  >,
) => {
  const optionsWithRefetchConfig = useMemo(() => {
    return {
      ...options,
      refetchInterval,
      // Need to always refetch on window focus to
      // get latest data after connection flow completes
      staleTime: 0,
    }
  }, [options])

  return useOptimisticQuery({
    queryName: "OAuthConnections",
    variables,
    fetcher: useFetcher<OAuthConnectionsQuery, OAuthConnectionsQueryVariables>(
      OAuthConnectionsDocument,
    ).bind(null, variables),
    fromNetworkObject: marshalOAuthConnections,
    options: optionsWithRefetchConfig,
    getPageInfo: (data) => data.currentAccount?.oAuthConnections.pageInfo,
    applyPatch: applyOAuthConnectionPatch,
  })
}

export const useOAuthConnectionOperations = () =>
  useOptimisticMutations({
    queryName: "OAuthConnections",
    additionalInvalidationKeys: [useCalendarAccountsQuery.getKey(), ["Items"]],
    create: undefined,
    update: {
      fetcher: useFetcher<
        UpdateOAuthConnectionMutation,
        UpdateOAuthConnectionMutationVariables
      >(UpdateOAuthConnectionDocument),
    },
    delete: {
      fetcher: useFetcher<
        DeleteOAuthConnectionMutation,
        DeleteOAuthConnectionMutationVariables
      >(DeleteOAuthConnectionDocument),
    },
  })
