import React from "react"
import { cn } from "@daybridge/cn"
import { useRefWithBackup } from "../../lib/useRefWithBackup"
import { useInputGroup } from "./InputGroup"

export type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "children"
> & {
  touched?: boolean
  error?: string
}

const InputFn = React.forwardRef(
  (props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className, touched, error, ...rest } = props
    const { labelId, descriptionId } = useInputGroup()
    const inputRef = useRefWithBackup(ref)

    return (
      <input
        ref={inputRef}
        aria-labelledby={labelId}
        aria-describedby={descriptionId}
        className={cn(
          "w-full max-w-xs",
          "px-[1em] py-[0.6em]",
          "text-base",
          "bg-elevated",
          "border border-tint",
          "hover:border-tint-heavy focus-visible:border-tint-heavy aria-expanded:border-tint-heavy",
          "focus-visible:shadow-sm",
          "transition duration-200 ease-in-out",
          "rounded-lg",
          "outline-none",
          "!ring-offset-0",
          "text-high-contrast placeholder:text-low-contrast",
          touched && error
            ? "ring-1 ring-state-error-primary ring-opacity-40 bg-gradient-to-t from-state-error-background to-elevated text-state-error-body"
            : "text-high-contrast",

          className,
        )}
        {...rest}
      />
    )
  },
)
InputFn.displayName = "Input"

export const Input = React.memo(InputFn) as typeof InputFn
