import {
  ColorPicker,
  Combobox,
  Dropdown,
  MenuItem,
  MenuItemGroup,
  MenuPage,
} from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useState } from "react"
import { partition } from "lodash"
import { v4 } from "uuid"
import { Tag } from "../../data/tags/Tag"
import { useIconsMenu } from "../icons/icons-menu"
import { useTagOperations, useTags } from "../../data/tags/useTags"

export interface TagsMenuGeneratorOptions {
  selectedTags?: string[]
  onTagSelect?: (tag: Tag, selected: boolean) => void
  allowReorder?: boolean
}

type TagMenuGenerator = (opts: TagsMenuGeneratorOptions) => MenuPage

export interface TagsMenuOptions {
  selectionMode?: MenuItemGroup["selectionMode"]
  partitioned?: boolean
  showTagEditMenu?: boolean

  // "No Tags" option
  showNoTagOption?: boolean
  noTagOptionSelected?: boolean
  onNoTagChange?: (selected: boolean) => void

  // Override interal renaming
  creating?: boolean
  onCreateComplete?: () => void
}

export const useTagsMenu = (options?: TagsMenuOptions): TagMenuGenerator => {
  // Translations
  const t = useTranslations("tags")
  const tNoResults = useTranslations("default_no_results_state")

  const { data: tags = [] } = useTags()
  const {
    create: { mutate: createTag },
    update: { mutate: updateTag },
    delete: { mutate: deleteTag },
    reorder: {
      mutate: reorderTags,
      moveItemUp: moveTagUp,
      moveItemDown: moveTagDown,
    },
  } = useTagOperations()

  // Renaming
  const [renamingTagId, setRenamingTagId] = useState<string | undefined>()

  // Icons
  const icons = useIconsMenu()

  // Tag customization
  const tagCustomizationMenu = useCallback(
    (tag: Tag, opts: TagsMenuGeneratorOptions): MenuPage => {
      const order = tags.map((tag) => tag.id)
      return {
        breadcrumbHue: tag.hue,
        items: [
          {
            groupId: "rename",
            items: [
              {
                id: "rename",
                title: t("rename"),
                icon: "Pencil",
                onSelect: () => {
                  setRenamingTagId(tag.id)
                  return false
                },
              },
            ],
          },

          {
            groupId: "colour-and-icon-pickers",
            items: [
              {
                id: "icon_picker",
                icon: tag.icon ?? "Tag",
                title: t("choose_icon"),
                expandChildrenAs: {
                  mode: "combobox" as const,
                  component: Combobox,
                },
                children: icons(
                  tag.icon ? [tag.icon] : [],
                  (icon) => {
                    updateTag({
                      input: {
                        id: tag.id,
                        patch: {
                          icon: icon ? { id: icon } : null,
                        },
                      },
                    })
                  },
                  true,
                ),
              },
              <ColorPicker
                className="p-2"
                key="color_picker"
                selectedHue={tag.hue}
                onHueSelect={(hue) => {
                  if (hue !== tag.hue) {
                    updateTag({
                      input: {
                        id: tag.id,
                        patch: {
                          color: {
                            hue,
                          },
                        },
                      },
                    })
                  }
                }}
              />,
            ],
          },
          ...(opts?.allowReorder
            ? [
                {
                  groupId: "move-up-down",
                  items: [
                    {
                      id: "move_up",
                      icon: "ArrowUp",
                      title: t("move_up"),
                      onSelect: () => {
                        moveTagUp(order, tag.id)
                      },
                      maxSearchDepth: 0, // Doesn't make sense to search for this
                    },
                    {
                      id: "move_down",
                      icon: "ArrowDown",
                      title: t("move_down"),
                      onSelect: () => {
                        moveTagDown(order, tag.id)
                      },
                      maxSearchDepth: 0, // Doesn't make sense to search for this
                    },
                  ],
                },
              ]
            : []),
          {
            groupId: "delete",
            items: [
              {
                id: "delete",
                icon: "TrashEmpty",
                title: t("delete"),
                onSelect: () => {
                  void deleteTag({ input: { id: tag.id } })
                },
              },
            ],
          },
        ],
      }
    },
    [
      t,
      updateTag,
      deleteTag,
      icons,
      setRenamingTagId,
      tags,
      moveTagUp,
      moveTagDown,
    ],
  )

  // Individual tags
  const tagItem = useCallback(
    (tag: Tag, opts: TagsMenuGeneratorOptions): MenuItem => {
      const selected = opts?.selectedTags?.includes(tag.id) ?? false

      return {
        id: tag.id,
        title: tag.name,
        hue: tag.hue,
        icon: tag.icon,
        selected,
        renaming: renamingTagId === tag.id,
        maxNameLength: 32,
        onRename: (name: string) => {
          if (name === tag.name) return
          void updateTag({
            input: {
              id: tag.id,
              patch: {
                name,
              },
            },
          })
        },
        onRenameCancel: () => {
          setRenamingTagId(undefined)
        },
        onSelect: () => opts?.onTagSelect?.(tag, !selected),
        expandChildrenAs: { mode: "menu", component: Dropdown },
        maxSearchDepth: 1,
        children: options?.showTagEditMenu
          ? tagCustomizationMenu(tag, opts)
          : undefined,
      }
    },
    [renamingTagId, updateTag, tagCustomizationMenu, options, setRenamingTagId],
  )

  // Menu items
  const items = useCallback(
    (opts: TagsMenuGeneratorOptions) => {
      const partitioned = options?.partitioned
        ? partition(tags, (tag) => opts.selectedTags?.includes(tag.id) ?? false)
        : [tags]

      return [
        ...(options?.showNoTagOption
          ? [
              {
                groupId: "no-tag",
                selectionMode: "checkbox" as const,
                items: [
                  {
                    id: "no-tag",
                    title: "(" + t("no_tag") + ")",
                    selected: options.noTagOptionSelected,
                    onSelect: () =>
                      options.onNoTagChange?.(!options.noTagOptionSelected),
                    icon: "Minus",
                  },
                ],
              },
            ]
          : []),
        ...partitioned
          .map(
            (tags, index): MenuItemGroup => ({
              groupId: "tags-" + index.toString(),
              selectionMode: options?.selectionMode,
              onReorder:
                opts?.allowReorder &&
                (!options?.partitioned ||
                  (partitioned[0].length > 0 && index === 0))
                  ? (_, order) => reorderTags(order)
                  : undefined,
              items: [
                ...(tags?.map((tag) => tagItem(tag, opts)) || []),
                ...(index === partitioned.length - 1 && options?.creating
                  ? [
                      {
                        id: "create-tag",
                        title: t("new_tag"),
                        renaming: true,
                        onRename: (name: string) => {
                          options?.onCreateComplete?.()
                          createTag({
                            input: {
                              idempotencyKey: v4(),
                              create: {
                                name,
                                color: {
                                  hue: Math.floor(Math.random() * 12) * 30,
                                },
                              },
                            },
                          })
                        },
                        onRenameCancel: () => {
                          options?.onCreateComplete?.()
                        },
                        icon: "Tag",
                      },
                    ]
                  : []),
              ],
            }),
          )
          .filter((group) => group.items.length > 0),
      ]
    },
    [options, t, tagItem, createTag, tags, reorderTags],
  )

  // Menu page
  return useCallback(
    (opts: TagsMenuGeneratorOptions) => ({
      prompt: t("search_tags") + "...",
      noResultsIcon: "Tag",
      emptyStateIcon: "Tag",
      emptyStateTitle: t("create_your_first_tag"),
      emptyStateDescription: t("create_your_first_tag_description"),
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      items: items(opts),
    }),
    [t, tNoResults, items],
  )
}
