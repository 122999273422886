import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"

export type NotificationsContext = {
  permission?: NotificationPermission
  showPermissionsPrompt: boolean
  acceptPermissionsPrompt: () => void
  dismissPermissionsPrompt: () => void
}

const notificationsContext = createContext<NotificationsContext>({
  showPermissionsPrompt: false,
  permission: "default",
  acceptPermissionsPrompt: () => {
    throw new Error(
      "acceptPermissionsPrompt can only be called inside PushNotificationsProvider",
    )
  },
  dismissPermissionsPrompt: () => {
    throw new Error(
      "dismissPermissionsPrompt can only be called inside PushNotificationsProvider",
    )
  },
})

export const NotificationsProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    // Permissions prompt management
    const NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY =
      "notification-prompt-response"
    const NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE = "false"

    const notification =
      typeof window !== "undefined" ? window.Notification : undefined
    const [showPermissionPrompt, setShowPermissionPrompt] = useState(false)
    const alreadyDismissed =
      typeof window !== "undefined"
        ? window.localStorage.getItem(
            NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY,
          ) === NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE
        : false

    const onDismiss = useCallback(() => {
      if (typeof window === "undefined") return

      window.localStorage.setItem(
        NOTIFICATION_PROMPT_ANSWERED_STORAGE_KEY,
        NOTIFICATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE,
      )
      setShowPermissionPrompt(false)
    }, [])

    useEffect(() => {
      if (!notification) return

      switch (notification.permission) {
        case "granted":
          // Permission already granted - start now
          setShowPermissionPrompt(false)
          break
        case "denied":
          // Dont' need to ask for permission
          setShowPermissionPrompt(false)
          break
        default:
          if (alreadyDismissed) {
            // The user has already said no to the popup dialog
            setShowPermissionPrompt(false)
            return
          } else {
            setShowPermissionPrompt(true)
          }
          break
      }
    }, [alreadyDismissed, notification, notification?.permission])

    const acceptPermissionsPrompt = useCallback(() => {
      if (!notification) return
      void notification.requestPermission().then(() => {
        setShowPermissionPrompt(false)
      })
    }, [notification])

    return (
      <notificationsContext.Provider
        value={{
          showPermissionsPrompt: showPermissionPrompt,
          acceptPermissionsPrompt,
          dismissPermissionsPrompt: onDismiss,
          permission: notification?.permission,
        }}
      >
        {children}
      </notificationsContext.Provider>
    )
  },
)
NotificationsProvider.displayName = "NotificationsProvider"

export const useBrowserNotifications = () => useContext(notificationsContext)
