import { useTranslations } from "next-intl"
import { useCallback } from "react"
import { useAccount } from "../account/useAccount"
import { Calendar, UserCalendarParticipant } from "./Calendar"

export const useCalendarSharingDescription = () => {
  const { data: user } = useAccount()
  const t = useTranslations("calendars")
  return useCallback(
    (calendar?: Calendar) => {
      if (!user || !calendar) {
        return undefined
      }
      const otherParticipant = calendar.participants?.find(
        (p) => (p as UserCalendarParticipant).user?.id !== user?.id,
      )

      if (calendar.participants && calendar.participants.length === 2) {
        return t("shared_with_name", {
          name:
            otherParticipant?.type === "user"
              ? otherParticipant.user.name
              : otherParticipant?.type === "email"
              ? otherParticipant.emailAddress
              : "",
        })
      } else if (calendar.participants && calendar.participants.length > 2) {
        return t("shared_with_n_people", {
          n: calendar.participants.length - 1,
        })
      }

      return undefined
    },
    [user, t],
  )
}
