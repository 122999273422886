import {
  Combobox,
  Dropdown,
  MenuItem,
  MenuItemGroup,
  MenuPage,
} from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useState } from "react"
import { groupBy } from "lodash"
import { OAuthConnectionStatus } from "../../data/_gen/types"
import { OAuthConnection } from "../../data/oauth/oauth-connection"
import { useResyncOAuthConnection } from "../../data/oauth/useResyncOAuthConnection"
import { useConnectOAuthAccount } from "../../data/oauth/useConnectOAuthAccount"
import {
  useOAuthConnectionOperations,
  useOAuthConnections,
} from "../../data/oauth/useOAuthConnections"
import { ConnectionStatus } from "../../app/[locale]/(boundary)/(calendar)/_components/connected-accounts/ConnectionStatus"
import { useAreasMenu } from "../areas/areas-menu"
import { AreaLabel } from "../../app/[locale]/(boundary)/(calendar)/_components/areas/AreaLabel"
import { useConnectAccountMenu } from "./connect-account-menu"

export type ConnectedAccountsMenuInstanceOptions = {
  selectedItemIds?: string[]
  onItemSelect?: (itemId: string) => void
}

export type ConnectedAccountsMenuOptions = {
  // Hook options go here...
}

type ConnectedAccountsMenuInstanceGenerator = (
  opts?: ConnectedAccountsMenuInstanceOptions,
) => MenuPage

export const useConnectedAccountOptionsMenu = (opts?: {
  onRename?: (id: string) => void
}) => {
  const t = useTranslations("connected_accounts")
  const {
    update: { mutate: updateConnection },
    delete: { mutate: deleteConnection },
  } = useOAuthConnectionOperations()

  const areaMenu = useAreasMenu({
    showNoAreaOption: true,
    selectionMode: "radio",
  })

  const onRename = opts?.onRename

  const resync = useResyncOAuthConnection()
  const connect = useConnectOAuthAccount()
  return useCallback(
    (
      account: Pick<
        OAuthConnection,
        "id" | "providerId" | "status" | "defaultArea"
      >,
      showResync = true,
    ): MenuPage => {
      const items: (MenuItem | MenuItemGroup)[] = [
        ...(account.status === OAuthConnectionStatus.RequiresReconnection
          ? [
              {
                id: "reconnect",
                title: t("fix_connection"),
                icon: "Diy",
                onSelect: async () => {
                  await connect(account.providerId)
                },
              },
            ]
          : []),
        {
          id: "rename",
          title: t("rename"),
          icon: "Pencil",
          onSelect: () => {
            onRename?.(account.id)
            return false
          },
        },
        {
          id: "assign-to-area",
          title: t("assign_to_area"),
          icon: "Area",
          expandChildrenAs: {
            mode: "combobox",
            component: Combobox,
          },
          children: areaMenu({
            noAreaOptionSelected: !account.defaultArea,
            onNoAreaChange: (selected) => {
              if (selected) {
                updateConnection({
                  input: {
                    id: account.id,
                    patch: {
                      defaultArea: null,
                    },
                  },
                })
              }
            },
            selectedAreas: account.defaultArea ? [account.defaultArea.id] : [],
            onAreaSelect: (area) =>
              updateConnection({
                input: {
                  id: account.id,
                  patch: {
                    defaultArea: area.id,
                  },
                },
              }),
          }),
        },
        {
          groupId: "capabilities",
          selectionMode: "switch",
          items: [
            {
              id: "calendars",
              title: t("calendars"),
              icon: "Calendar",
              selected: false, // TODO
              disabled: false, // TODO
              onSelect: async () => {
                await connect(account.providerId)
              },
              maxSearchDepth: 0,
            },
            {
              id: "contacts",
              title: t("contacts"),
              icon: "Contacts",
              selected: false, // TODO
              disabled: false, // TODO
              onSelect: async () => {
                await connect(account.providerId)
              },
              maxSearchDepth: 0,
            },
          ],
        },
        {
          groupId: "resync",
          items: [
            ...(account.status !== OAuthConnectionStatus.Creating &&
            account.status !== OAuthConnectionStatus.Syncing &&
            showResync
              ? [
                  {
                    id: "resync",
                    title: t("resync_everything"),
                    description: t("resync_everything_description"),
                    hue: 55,
                    icon: "Repeat",
                    onSelect: () => {
                      resync(account.id)
                    },
                  },
                ]
              : []),

            {
              id: "delete",
              title: t("disconnect"),
              icon: "Cross",
              hue: 0,
              onSelect: () => {
                deleteConnection({
                  input: { id: account.id },
                })
              },
            },
          ],
        },
      ]

      return {
        items,
      }
    },
    [
      onRename,
      t,
      deleteConnection,
      resync,
      connect,
      updateConnection,
      areaMenu,
    ],
  )
}

export const useConnectedAccountsMenu =
  (): ConnectedAccountsMenuInstanceGenerator => {
    const t = useTranslations("connected_accounts")
    const tNoResults = useTranslations("default_no_results_state")

    const connectMenu = useConnectAccountMenu()

    const optionsFor = useConnectedAccountOptionsMenu({
      onRename: (id) => {
        setRenamingConnectionId(id)
      },
    })

    const { data: oAuthConnections } = useOAuthConnections()
    const {
      update: { mutate: updateConnection },
    } = useOAuthConnectionOperations()

    // Renaming
    const [renamingConnectionId, setRenamingConnectionId] = useState<
      string | undefined
    >()

    const items = useCallback((): MenuPage["items"] => {
      const groupedAccounts = groupBy(
        oAuthConnections.filter(
          (a) => a.status !== OAuthConnectionStatus.Deleting,
        ) || [],
        "providerId",
      )
      const sortedProviders = Object.keys(groupedAccounts).sort()

      return [
        ...sortedProviders.map((providerId) => {
          const providerName =
            providerId.charAt(0).toUpperCase() + providerId.slice(1)
          return {
            groupId: providerId,
            title: providerName,
            icon: providerName,
            items: groupedAccounts[providerId].map((account) => {
              const item: MenuItem = {
                id: account.id,
                title: account.customName ?? account.email ?? providerName,
                description: account.customName ? account.email : undefined,
                icon: "Connection",
                hue: account.defaultArea?.hue,
                rightContent:
                  account.status !== OAuthConnectionStatus.Connected ? (
                    <ConnectionStatus account={account} />
                  ) : (
                    <AreaLabel
                      area={account.defaultArea}
                      className="ml-4 mr-1 max-w-32"
                    />
                  ),
                children: optionsFor(account),
                expandChildrenAs: {
                  component: Dropdown,
                  mode: "menu",
                },
                renaming: account.id === renamingConnectionId,
                maxNameLength: 64,
                onRename: (name: string) => {
                  if (name === account.customName) return
                  updateConnection({
                    input: {
                      id: account.id,
                      patch: { customName: name },
                    },
                  })
                },
                onRenameCancel: () => {
                  setRenamingConnectionId(undefined)
                },
                allowEmptyName: true,
              }
              return item
            }),
          }
        }),
        ...connectMenu().items,
      ]
    }, [
      optionsFor,
      renamingConnectionId,
      oAuthConnections,
      updateConnection,
      connectMenu,
    ])

    return useCallback(
      (): MenuPage => ({
        noResultsTitle: tNoResults("no_results"),
        noResultsDescription: tNoResults("no_results_description"),
        emptyStateIcon: "Connection",
        emptyStateTitle: t("no_connected_accounts"),
        emptyStateDescription: t("no_connected_accounts_description"),
        items: items(),
      }),
      [items, t, tNoResults],
    )
  }
