import React, { useCallback, useMemo, useState } from "react"
import { Combobox, Popover, PopoverProps } from "@daybridge/components"
import { cn } from "@daybridge/cn"
import {
  AreasMenuGeneratorOptions,
  AreasMenuOptions,
  useAreasMenu,
} from "./areas-menu"
import { useCreateAreaMenu } from "./create-area-menu"

export type AreasPickerProps = Omit<PopoverProps, "content"> &
  AreasMenuOptions &
  AreasMenuGeneratorOptions

const AreasPickerFn = React.forwardRef(
  (props: AreasPickerProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      selectionMode,
      showCalendarCount,
      showAreaEditMenu,
      showNoAreaOption,
      noAreaOptionSelected,
      onNoAreaChange,
      noAreaText,
      noAreaIcon,
      selectedAreas,
      onAreaSelect,
      allowReorder,
      ...rest
    } = props

    const [open, setOpen] = React.useState(false)

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={
          <AreasCombobox
            selectionMode={selectionMode}
            showAreaEditMenu={showAreaEditMenu}
            showNoAreaOption={showNoAreaOption}
            showCalendarCount={showCalendarCount}
            noAreaOptionSelected={noAreaOptionSelected}
            onNoAreaChange={onNoAreaChange}
            noAreaText={noAreaText}
            noAreaIcon={noAreaIcon}
            onComplete={() => setOpen(false)}
            selectedAreas={selectedAreas}
            onAreaSelect={onAreaSelect}
            allowReorder={allowReorder}
          />
        }
        className={cn("w-80", className)}
        updatePositionStrategy="always"
        ref={ref}
        {...rest}
      >
        {children}
      </Popover>
    )
  },
)
AreasPickerFn.displayName = "AreasPicker"
export const AreasPicker = React.memo(AreasPickerFn) as typeof AreasPickerFn

export type AreasComboboxProps = {
  onComplete?: () => void
  showCreateArea?: boolean
  maxHeight?: React.CSSProperties["maxHeight"]
} & AreasMenuOptions &
  AreasMenuGeneratorOptions
const AreasComboboxFn = React.forwardRef(
  (props: AreasComboboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      onComplete,
      selectionMode,
      showCalendarCount,
      showAreaEditMenu,
      showNoAreaOption,
      noAreaOptionSelected,
      onNoAreaChange,
      noAreaText,
      noAreaIcon,
      selectedAreas,
      onAreaSelect,
      allowReorder,
      showCreateArea = true,
      maxHeight,
    } = props

    const [creating, setCreating] = useState(false)
    const createMenu = useCreateAreaMenu(() => {
      setCreating(true)
    })
    const onCreateComplete = useCallback(() => {
      setCreating(false)
    }, [])

    const root = useAreasMenu({
      selectionMode,
      showCalendarCount,
      showAreaEditMenu,
      showNoAreaOption,
      creating,
      onCreateComplete,
    })

    const rootBound = useMemo(() => {
      return root({
        selectedAreas,
        onAreaSelect,
        allowReorder,
        noAreaOptionSelected,
        onNoAreaChange,
        noAreaText,
        noAreaIcon,
      })
    }, [
      root,
      selectedAreas,
      onAreaSelect,
      allowReorder,
      onNoAreaChange,
      noAreaOptionSelected,
      noAreaText,
      noAreaIcon,
    ])

    return (
      <Combobox
        ref={ref}
        root={rootBound}
        onComplete={onComplete}
        pinnedItems={showCreateArea ? [createMenu] : []}
        maxHeight={maxHeight}
      />
    )
  },
)
AreasComboboxFn.displayName = "AreasCombobox"
export const AreasCombobox = React.memo(
  AreasComboboxFn,
) as typeof AreasComboboxFn
