import React from "react"
import { cn } from "@daybridge/cn"
import { Icon, Label, LabelProps } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { OAuthConnection } from "../../../../../../data/oauth/oauth-connection"
import {
  IconId,
  OAuthConnectionStatus,
} from "../../../../../../data/_gen/types"

type ConnectionStatusProps = Omit<LabelProps, "children"> & {
  account: Pick<OAuthConnection, "status">
}

const ConnectionStatusFn = React.forwardRef(
  (props: ConnectionStatusProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, account, ...rest } = props
    const t = useTranslations("connected_accounts")

    return (
      <Label
        ref={ref}
        className={cn("text-sm", className)}
        icon={
          account.status === OAuthConnectionStatus.Connected
            ? "Tick"
            : [
                OAuthConnectionStatus.Creating,
                OAuthConnectionStatus.Syncing,
              ].includes(account.status)
            ? undefined
            : "Warning"
        }
        theme={
          account.status === OAuthConnectionStatus.Connected
            ? "success"
            : [
                OAuthConnectionStatus.Creating,
                OAuthConnectionStatus.Syncing,
              ].includes(account.status)
            ? "warning"
            : "error"
        }
        {...rest}
      >
        {(account.status === OAuthConnectionStatus.Creating ||
          account.status === OAuthConnectionStatus.Syncing) && (
          <Icon
            name={"sync" as IconId} // TODO ICON
            className="inline w-2.5 h-2.5 mr-1 mb-0.5 animate-spin"
          />
        )}
        {t(account.status)}
      </Label>
    )
  },
)
ConnectionStatusFn.displayName = "ConnectionStatus"

export const ConnectionStatus = React.memo(
  ConnectionStatusFn,
) as typeof ConnectionStatusFn
